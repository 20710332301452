@import url('https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css');

@import url('https://fonts.googleapis.com/css?family=Roboto');


html, html body {
 /*background-color: #f2f9ff; */
  font-family: 'Roboto', sans-serif;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: #0D71BB;
  border-bottom: 4px solid #CCCCCC;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\29BE";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\29BF";
  color: #0D71BB;
  background-color: white;
  width: 1.2em;
  line-height: 1.2em;

}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.footer-buttons {
  padding-bottom: 100px;
}

.ui.grid.photo-grid {
  margin-top: 1em;
}

.ui.card.sep-card {
  padding:10px;
  margin-bottom: 1em;
}

.white-pin {
  color:white;
  cursor: pointer;
}

.ui.grid>.short-row {
  padding-top:0;
  padding-bottom: 0;
}

.red-text, .ui.header.red-text, .ui.card>.content>.header.red-text {
  color: #9f3a38
}

.ui.accordion .title:not(.ui) {
  font-family: 'Roboto', sans-serif;
}

.ui.card>.fill-card {
  padding: 0.2em;
}

#dummy-equip.value, #dummy-age-value, .ui.button.hidden-button {
  display:none;
}

.tiny-text {
  font-size: 0.8rem;
}

.highlight-yellow {
  background-color: yellow;
}

.highlight-green {
  background-color: lightgreen;
}

.indented {
  padding-left: 10%;
}

.ui.huge.header.humongous {
  font-size: 3em;
}

.ui.right.floated.image.arrow-1 {
  position: absolute;
  right: 30%;
  bottom: 70%
}

.ui.right.floated.image.arrow-1-med {
  position: absolute;
  right: 25%;
  bottom: 65%
}

.ui.right.floated.image.arrow-1-mobile {
  position: absolute;
  right: 20%;
  bottom: 70%
}

.ui.right.floated.image.arrow-1-tablet {
  position: absolute;
  right: 20%;
  bottom: 70%
}

.ui.right.floated.image.arrow-2 {
  position: absolute;
  right: 23%;
  bottom: 93%
}

.ui.right.floated.image.arrow-2-med {
  position: absolute;
  right: 25%;
  bottom: 93%
}

.pointer {
  cursor: pointer
}

.squeezeTop {
  margin-top: -15px;
}

.ui.right.floated.image.blob-float {
  position: absolute;
  right: 10%;
  bottom: 78%
}

.ui.image.steps-blobby {
  margin-top: 20px;
  margin-left: 15px;
}

.small-white-text {
  color:white;
  font-size:0.5em;
  margin-left:5px;
  text-align: center;
}

.friendly-font{
  font-family: 'Jua', sans-serif;
  font-weight: normal;
  font-size: 1.2em
}

 .ui.header.friendly-font {
  font-family: 'Jua', sans-serif;
  font-weight: normal;

}

.action-pill {
  border: solid 2px black;

  background-color: #ffd93b;
  padding:20px;

  border-radius:10px;
  width:100%;
}

.action-pill-mobile {
  border: solid 2px black;

  background-color: #ffd93b;
  padding:5px;

  border-radius:10px;

  width:100%;
}

.share-icon {
  background-color: black;
  padding:7px;

  border-radius:4px;
}

.force-width {
  width:100%;
}

.kill-lr-margins{
  margin-left: -20px;
  margin-right: -20px;
}

.kill-lr-padding{
  padding-left:0;
  padding-right: 0;
}

.kill-tb-margins{
  margin-top: -10px;
  margin-bottom: -10px;
}

.ui.centered.grid, .ui.centered.grid>.row, .ui.grid>.centered.row {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.ui.grid>.row.thin-row {
  padding-top: 0;
  padding-bottom: 0;
}

.jua {
  font-family: "jua";
}

.ui.grid>.row>.column.comic-column {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.ui.grid>.row.comic-row {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.fbcentre {
  text-align: center; 
  width: 100%;
}