.backgroundBlack {
  background-color:'black'
}

.colorWhite {
  color: white;
}

.displayInlineBlock {
  display:"inline-block"
}

.fontSize07 {
  font-size:'0.7em'
}

.fontSize11 {
  font-size:'1.1em'
}

.lineHeight120 {
  line-height:"120%"
}

.marginLeft5 {
  margin-left:'5px'
}

.marginLeft20 {
  margin-left:'20px'
}

.marginLeftAuto {
  margin-left: auto;
}

.marginRightAuto {
  margin-right: auto;
}

.marginTop10 {
  margin-top:'10px'
}

.pad5-0 {
  padding:'5px 0px'
}

.pad5-10 {
  padding:'5px 10px'
}

.padRight5 {
  padding-right:'5px'
}

.padTop10 {
  padding-top:'10px'
}

.padTop20 {
  padding-top:'20px'
}

.width85 {
  width: 85%;
}